import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { graphql } from 'gatsby'
import RichText from '../components/RichText'

const IndexPage = ({
  data: {
    contentfulContentPage: { title, content },
  },
}) => {
  // const {
  //   contentfulContentPage: { title, content },
  // } = useStaticQuery(contentPageQuer)

  // const newsletterRef = React.useRef(null)

  // const IntroText = ({ index, introText, path, callToAction }) => (
  //   <div key={index} className="py-6">
  //     <p>{introText}</p>
  //     <Link to={path} className="underline hover:text-gray">
  //       {callToAction}
  //     </Link>
  //   </div>
  // )

  return (
    <Layout>
      <Seo title={title} />
      {content && <RichText content={content} />}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    contentfulContentPage(path: { eq: "/" }) {
      path
      title
      content {
        raw
        references {
          ... on Node {
            ... on ContentfulNavButtonListing {
              __typename
              contentful_id
              name
              navButtons {
                buttonCta
                toPath
                color
                external
                externalUrl
                externalTargetBlank
                disabled
              }
            }
            ... on ContentfulNavCardListing {
              __typename
              contentful_id
              name
              navCards {
                cardTitle
                cardCta
                toPath
                image {
                  gatsbyImageData(layout: CONSTRAINED, width: 512, height: 512)
                }
                external
                externalUrl
                externalTargetBlank
                comingSoon
                disabled
              }
            }
            ... on ContentfulNewsletterSignup {
              __typename
              contentful_id
              name
              inputPlaceholder
              cta
              errorMessage
              successMessage
            }

            ... on ContentfulEventListing {
              __typename
              contentful_id
              name
              events {
                name
                description {
                  description
                }
                start
                end
                buttonCta
                url
              }
            }
            ... on ContentfulBoredApeOutfitListing {
              __typename
              contentful_id
              boredApeOutfits {
                name
                fromDate
                toDate
                apeWithOutfit {
                  gatsbyImageData(layout: CONSTRAINED, width: 512, height: 512)
                }

                outfit {
                  gatsbyImageData(layout: CONSTRAINED, width: 512, height: 512)
                  localFile {
                    id
                    publicURL
                  }
                }
              }
            }
            ... on ContentfulEmbeddedImage {
              __typename
              contentful_id
              alignment
              altText
              image {
                gatsbyImageData(width: 1280)
              }
            }
            ... on ContentfulNftListing {
              __typename
              contentful_id
              name
              nfts {
                title
                image {
                  gatsbyImageData(layout: CONSTRAINED, width: 512, height: 512)
                }
                text
                linkUrl
                webLinkText
                webLinkUrl
                twitterLinkText
                twitterLinkUrl
                discordLinkText
                discordLinkUrl
                blogLinkText
                blogLinkUrl
              }
            }
            ... on ContentfulCannabisInfusedProduct {
              __typename
              contentful_id
              name
              ratio
              color
              description {
                description
              }
              alignment
              productImage {
                gatsbyImageData(width: 512)
              }
              graphImage {
                gatsbyImageData(width: 512)
              }
            }
            ... on ContentfulBoredApeOutfitDownloadButton {
              __typename
              contentful_id
              buttonCta
              boredApeOutfit {
                name
                fromDate
                toDate
                apeWithOutfit {
                  gatsbyImageData(layout: CONSTRAINED, width: 512, height: 512)
                }

                outfit {
                  gatsbyImageData(layout: CONSTRAINED, width: 512, height: 512)
                  localFile {
                    id
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
